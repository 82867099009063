import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["keepOpen", "modalFrame"];

  connect() {
    this.modal = new bootstrap.Modal(this.element);
  }

  open() {
    if (!this.modal.isOpened) {
      this.element.addEventListener(
        "shown.bs.modal",
        () => {
          const autofocusElement = this.element.querySelector("[autofocus]");
          if (autofocusElement) {
            autofocusElement.focus();
          }
        },
        { once: true },
      );

      this.modal.show();
    }
  }

  submitting() {
    this.createOverlay();
  }

  close(event) {
    if (event.detail.success && !this.keepOpen()) {
      this.modal.hide();
    }

    this.destroyOverlay();
  }

  keepOpen() {
    if (this.hasKeepOpenTarget) {
      return this.keepOpenTarget.value === "true";
    } else {
      return false;
    }
  }

  setKeepOpen(event) {
    const form = event.target.closest("form");
    if (form && this.hasKeepOpenTarget) {
      event.preventDefault();

      this.keepOpenTarget.value = true;
      form.dataset.turboFrame = this.modalFrameTarget.id;
      form.requestSubmit();
    }
  }

  createOverlay() {
    this.overlay = document.createElement("div");
    this.overlay.className =
      "position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center " +
      "justify-content-center bg-white opacity-75";
    this.overlay.style.zIndex = "1050";

    // Add a spinner inside the overlay
    const spinner = document.createElement("div");
    spinner.className = "spinner-border text-primary";
    spinner.role = "status";

    const spinnerText = document.createElement("span");
    spinnerText.className = "visually-hidden";
    spinnerText.textContent = "Loading...";

    spinner.appendChild(spinnerText);
    this.overlay.appendChild(spinner);

    // Append overlay to the modal content
    this.element.querySelector(".modal-body").appendChild(this.overlay);
  }

  destroyOverlay() {
    if (this.overlay && this.overlay.parentNode) {
      this.overlay.parentNode.removeChild(this.overlay);
      this.overlay = null;
    }
  }
}
