import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["vendorSelect", "recipientsField"];

  connect() {
    const selectedVendorId = this.vendorSelectTarget.value;
    this.updateRecipients(selectedVendorId);
  }

  change(event) {
    const selectedVendorId = event.target.value;
    if (selectedVendorId.includes("/vendors/new")) {
      const frame = document.getElementById("modal");
      frame.src = event.target.value;
      frame.reload();
    } else {
      this.updateRecipients(selectedVendorId);
    }
  }

  loadNewVendorForm() {
    fetch("/vendors/new", { headers: { Accept: "text/vnd.turbo-stream.html" } })
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;
      });
  }

  updateRecipients(vendorId) {
    if (!vendorId) return;

    fetch(`/vendors/${vendorId}/email_addresses`, {
      headers: { Accept: "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.recipients) {
          this.recipientsFieldTarget.value = data.recipients.join(", ");
        }
      })
      .then(() => {
        const workOrderDetails = document.getElementById("work-order-details");
        workOrderDetails.classList.remove("d-none");
      });
  }
}
