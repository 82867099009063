import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sameMeasurementsContainer", "imprintMeasurements"];

  toggle(event) {
    const reprinted = event.target.checked;
    const element = this.sameMeasurementsContainerTarget;

    element.style.display = reprinted ? "block" : "none";
  }

  setImprintMeasurements(event) {
    const sameMeasurements = event.target.checked;
    const element = this.imprintMeasurementsTarget;

    element.value = sameMeasurements ? "SAME" : "";
  }
}
