import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["step", "progressBar"];

  connect() {
    this.currentStep = 0;
    this.updateProgressBar();
    this.showStep();
    this.stepTargets.forEach((step) => {
      const inputs = step.querySelectorAll("input, select, textarea");
      inputs.forEach((input) => {
        input.addEventListener("input", this.resetValidation);
      });
    });
  }

  updateProgressBar() {
    const progressPercentage =
      ((this.currentStep + 1) / this.stepTargets.length) * 100;
    const progressBar = this.progressBarTarget;
    progressBar.setAttribute("aria-valuenow", progressPercentage);
    progressBar.style.width = `${progressPercentage}%`;
  }

  resetValidation(event) {
    const field = event.target;

    if (field.checkValidity()) {
      field.classList.remove("is-invalid");
      field.classList.add("is-valid");
    } else {
      field.classList.remove("is-valid");
    }
  }

  next(event) {
    event.preventDefault();

    if (!this.validateStep()) return;
    if (this.currentStep < this.stepTargets.length - 1) {
      this.currentStep++;
      if (this.currentStep === this.stepTargets.length - 1) {
        this.updateSummary(); // Update the summary step before showing it
      }
      this.updateProgressBar();
      this.showStep();
    }
  }

  updateSummary() {
    document.querySelector("#summary-description").textContent =
      this.element.querySelector("input[name='screen_print[description]']")
        ?.value || "N/A";

    document.querySelector("#summary-reprint").textContent =
      this.element.querySelector(
        "input[name='screen_print[reprint]'][type='checkbox']",
      )?.checked
        ? "Yes"
        : "No";

    // document.querySelector("#summary-last-print-date").textContent =
    //   this.element.querySelector("input[name='screen_print[last_print_date]']")
    //     ?.value || "N/A";

    document.querySelector("#summary-location").textContent =
      this.element.querySelector("select[name='screen_print[location_name]']")
        ?.value || "N/A";

    document.querySelector("#summary-imprint-measurements").textContent =
      this.element.querySelector(
        "input[name='screen_print[imprint_measurements]']",
      )?.value || "N/A";

    document.querySelector("#summary-colors").textContent =
      this.element.querySelector(
        "input[name='screen_print[colors]'][type='radio']:checked",
      )?.value || "N/A";

    const colorNamesInput = this.element.querySelector(
      "input[name='screen_print[color_names]']",
    );
    let colorNamesText = "N/A";

    if (colorNamesInput && colorNamesInput.value) {
      try {
        const colorNames = JSON.parse(colorNamesInput.value);
        colorNamesText = colorNames
          .map((color) => color.value.toUpperCase())
          .join(", ");
      } catch (e) {
        console.error("Invalid JSON in color names input", e);
      }
    }
    document.querySelector("#summary-color-names").textContent = colorNamesText;

    document.querySelector("#summary-flash").textContent =
      this.element.querySelector(
        "input[name='screen_print[flash]'][type='checkbox']",
      )?.checked
        ? "Yes"
        : "No";
  }

  previous(event) {
    event.preventDefault();

    if (this.currentStep > 0) {
      this.currentStep--;
      this.updateProgressBar();
      this.showStep();
    }
  }

  showStep() {
    this.stepTargets.forEach((step, index) => {
      step.style.display = index === this.currentStep ? "block" : "none";
    });

    const submitButton = this.element.querySelector("[type='submit']");
    const nextButton = this.element.querySelector(
      "[data-action='wizard#next']",
    );

    submitButton.style.display =
      this.currentStep === this.stepTargets.length - 1 ? "block" : "none";

    nextButton.style.display =
      this.currentStep === this.stepTargets.length - 1
        ? "none"
        : "inline-block";
  }

  validateStep() {
    const currentStepElement = this.stepTargets[this.currentStep];
    const inputs = Array.from(
      currentStepElement.querySelectorAll("input, select, textarea"),
    );

    const invalidFields = inputs.filter((input) => !input.checkValidity());

    inputs.forEach((field) => {
      field.classList.remove("is-invalid");
      field.classList.remove("is-valid");
      if (field.checkValidity()) {
        field.classList.add("is-valid");
      }
    });

    if (invalidFields.length > 0) {
      for (let i = invalidFields.length - 1; i >= 0; i--) {
        const field = invalidFields[i];
        field.classList.add("is-invalid");
        field.reportValidity();
      }

      invalidFields[0].focus();

      return false;
    }

    return true;
  }
}
