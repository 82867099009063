import { Controller } from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";

export default class extends Controller {
  static targets = ["input", "colorsGroup"];

  connect() {
    this.updateMaxTags();
    this.tagify = new Tagify(this.inputTarget, {
      delimiters: ",",
      whitelist: [],
      maxTags: this.getMaxTags(),
      dropdown: { enabled: 0 },
    });
  }

  colorCountChanged(_event) {
    this.updateMaxTags();
  }

  updateMaxTags() {
    const maxTags = this.getMaxTags();
    if (this.tagify) {
      this.tagify.settings.maxTags = maxTags;
    }
  }

  getMaxTags() {
    return (
      parseInt(this.colorsGroupTarget.querySelector(":checked")?.value, 10) || 8
    );
  }

  disconnect() {
    if (this.tagify) {
      this.tagify.destroy();
    }
  }
}
