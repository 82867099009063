import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["total", "more"];

  connect() {
    this.element.addEventListener(
      "quantity:changed",
      this.updateTotal.bind(this),
    );
  }

  updateTotal() {
    const allQuantities = document.querySelectorAll(
      "input[data-color-target='quantity']",
    );
    const total = Array.from(allQuantities).reduce((sum, input) => {
      return sum + (parseInt(input.value || 0, 10) || 0);
    }, 0);
    this.totalTarget.textContent = total;
  }
}
